<template>
  <div class="page-container">
    <div class="page-title">{{ $t("pages.profile.pageTitle") }}</div>
    <div class="personal-phone">
          <div class="personal-phone-num">+{{userInfo.msisdn}}</div>
          <button class="btn btn-small btn-primary" @click="userLogout">Выйти</button>
        </div>
    <div class="page-inner page-inner--profilePage">
      <div class="personal-container">
        <div>
          <winMonthPromoBanner v-for="promoItem in promocodeMonthly" :promoItem="promoItem"></winMonthPromoBanner>
          <p class="static-title">Общая статистика по играм</p>
          <gameDifficulty @changeLevel="changeLevel" addStyles="game-difficulty-profile"></gameDifficulty>
          <ul class="static-list">
            <staticCard v-for="game in gameArray" :key="game.id" :gameInfo="game"></staticCard>
          </ul>
        </div>
<!--        <div class="winners-block">-->
<!--          <router-link to="/winners" class="winners-download pdf">-->
<!--            {{ $t("pages.profile.winnersTitle") }}-->
<!--          </router-link>-->
<!--        </div>-->

      </div>

    </div>
  </div>
</template>


<script>
import { useUserStore } from '@/store/user';
import { useAuthStore } from '@/store/auth';
import { RouterLink, RouterView } from 'vue-router'
import { mapState, mapActions } from 'pinia';
import Modal from '@/components/modals/Modal.vue'
import staticCard from '../components/profile/staticCard.vue';
import gameDifficulty from '../components/profile/gameDifficulty.vue';
import winMonthPromoBanner from '../components/promo/winMonthPromoBanner.vue';

export default {
  components: {
    Modal,
    staticCard,
    gameDifficulty,
    RouterLink,
    winMonthPromoBanner
  },
  data() {
    return {
      showModal: false,
      currentLevel: 'low',
    };
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'phone']),
    ...mapState(useAuthStore, ['logout']),
    promocodeMonthly () {
      return this.userInfo.promocodeMonthly;
    },
    gameArray() {
      const infoGame = this.userInfo.games;
      const finishedArray = [];
      for (let game in infoGame) {
        if (game !== 'summary') {
          const gameStatsByLevels = infoGame[game].statsByLevels;
          if (this.currentLevel !== 'all' && gameStatsByLevels) {
            if (gameStatsByLevels[`total_available_${this.currentLevel}`] > 0) {
              finishedArray.push(
                {
                  name: this.getGameName(game),
                  win: gameStatsByLevels[`win_${this.currentLevel}`],
                  completed: gameStatsByLevels[`${this.currentLevel}`],
                  img: `${game}-${this.currentLevel}`
                }
              );
          }} else {
            finishedArray.push(
              {
                name: this.getGameName(game),
                win: infoGame[game].qtySuccess,
                completed: infoGame[game].qtyCompleted,
                img: `${game}-main`
              }
            )
          }
        }
      }
      finishedArray.sort((x, y) => x.completed < y.completed ? 1 : -1)
      return finishedArray;
    }
  },
  created() {
    // this.userPhone = this.phone;
  },
  methods: {
    getGameName(game) {
      let gameName;
      switch (game) {
          case 'fillword':
          gameName = 'Филворды';
        break;
          case 'sudoku':
          gameName = 'Судоку';
        break;
          case 'nonogram':
          gameName = 'Японские кроссворды';
        break;
        default:
          gameName = game;
          break;
      }
      return gameName;
    },
    changeLevel(value) {
      this.currentLevel = value;
    },
    modalClose() {
      this.showModal = false;
    },
    userLogout() {
      localStorage.removeItem('force_auth_ts');
      this.logout();
    }
  }
};
</script>

