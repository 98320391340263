<template>
  <div class="page-container">
    <div class="page-title">Судоку</div>
    <div class="page-inner game-page-inner game-detail" :class="[{'content-loading' : contentLoading}, {'loadLevel' : loadLevel}]">
      <div class="game-detail-top">
        <router-link class="go-back" to="/sudoku">{{ $t("nav.back") }}</router-link>
        <div class="game-difficulty-title">Уровень №{{ gameInfo.num }}</div>
<!--        <div @click="check(0)" style="margin-left: 10px;" tabindex="-1">lose</div>-->
<!--        <div @click="check(1)" style="margin-left: 10px;" tabindex="-1">win</div>-->
        <button class="game-reset" @click="gameReset()" :class="{disabled: !reset}">
          <svg width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <svg width="25" height="27" viewBox="0 0 25 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7693 0.36865C13.6194 0.270179 13.4438 0.212481 13.2617 0.201805C13.0796 0.191129 12.8979 0.227881 12.7363 0.308082C12.5747 0.388282 12.4393 0.508876 12.3447 0.656804C12.2502 0.804733 12.2001 0.97436 12.2 1.14732V3.44667C9.82663 3.44667 7.50655 4.1139 5.53316 5.36398C3.55977 6.61407 2.0217 8.39086 1.11345 10.4697C0.2052 12.5485 -0.0324398 14.836 0.430583 17.0428C0.893605 19.2497 2.03649 21.2768 3.71472 22.8678C5.39295 24.4589 7.53115 25.5424 9.85892 25.9814C12.1867 26.4204 14.5995 26.1951 16.7922 25.334C18.9849 24.4729 20.8591 23.0147 22.1776 21.1439C23.4962 19.273 24.2 17.0734 24.2 14.8233C24.2 14.4881 24.0595 14.1666 23.8095 13.9295C23.5594 13.6924 23.2203 13.5593 22.8667 13.5593C22.513 13.5593 22.1739 13.6924 21.9239 13.9295C21.6738 14.1666 21.5333 14.4881 21.5333 14.8233C21.5333 16.5734 20.9859 18.2842 19.9604 19.7393C18.9348 21.1944 17.4772 22.3286 15.7717 22.9983C14.0663 23.668 12.1896 23.8432 10.3792 23.5018C8.56867 23.1604 6.90563 22.3177 5.60034 21.0802C4.29505 19.8427 3.40614 18.266 3.04601 16.5496C2.68588 14.8331 2.87071 13.054 3.57713 11.4372C4.28355 9.8203 5.47983 8.43835 7.01468 7.46606C8.49406 6.52892 10.2239 6.01293 12 5.97685C12.0666 5.97549 12.1333 5.97482 12.2 5.97482V8.90747C12.2004 9.08032 12.2506 9.24978 12.3452 9.39753C12.4399 9.54528 12.5753 9.66569 12.7369 9.74574C12.8985 9.82578 13.0801 9.86243 13.2621 9.8517C13.444 9.84097 13.6194 9.78328 13.7693 9.68487L19.6787 5.80669C19.8118 5.71948 19.9206 5.60296 19.9957 5.46711C20.0709 5.33126 20.1101 5.18016 20.1101 5.02676C20.1101 4.87336 20.0709 4.72226 19.9957 4.58641C19.9206 4.45057 19.8118 4.33404 19.6787 4.24683L13.7693 0.36865Z" fill="#6D78B8"/>
            </svg>

          </svg>

        </button>
      </div>

      <div class="sudoku-game-container" v-click-outside="unSelectCell">
        <div class="sudoku-grid-wrap">
          <div class="sudoku-grid-lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
          </div>
          <div class="sudoku-grid">
            <cell v-for="item in gameGrid" :item="item" @pickCell="pickCell(item)" @clearCell="inputCell" @inputCell="inputCell"></cell>
          </div>
        </div>
        <div class="sudoku-numbers">
          <div v-for="index in 9" :key="index" @click="cellFill(index)" class="sudoku-numbers-item">
            {{index}}
          </div>
          <div @click="cellFill(0)" class="sudoku-numbers-item">x</div>
        </div>
      </div>

      <tips :tips="tipsArr" @buy="buy" @countDownEnd="countDownEnd" :loading="contentLoading"></tips>

      <Modal
          v-model="failModal"
          :title="'Неудача!'"
          @close="confirmClose"
      >
        <div class="buy-popup-text">
          Вы не правильно заполнили судоку, попробуйте пройти следующий уровень
        </div>
        <div class="buy-popup-reward">
          <img src="@/assets/img/lose.png" class="game-fail-img" alt="fail" />
        </div>

        <template #customFooter>
          <div class="modal-buttons-large mt-0">
            <div @click="toMainPage" class="btn btn-secondary">Главное меню</div>
<!--            <button @click="startNew" tabindex="-1" class="btn btn-primary">Следующий уровень</button>-->
            <div :to="`/${gameType}`" @click="nextlevel()" class="btn btn-primary">Следующий уровень</div>
          </div>
        </template>

      </Modal>

      <Modal
          v-model="winModal"
          :title="'Поздравляем!'"
          @close="confirmClose"
          class="modal-wrapper game-win-modal"
      >
        <div class="buy-popup-text">
          Вы прошли уровень №{{ gameInfo.num }}<br>
          Ваши призы:
        </div>
        <div class="game-rewards" v-if="prizes">
          <div class="reward points" v-if="prizes.points">+{{prizes.points}}</div>
          <div class="reward diamonds" v-if="prizes.diamonds">+{{prizes.diamonds}}</div>
          <div class="reward puzzle" v-if="prizes.puzzle">+{{prizes.puzzle}}</div>
        </div>
        <div class="game-reward-promo" v-if="prizePromo">
          <promoItem v-for="item in prizePromo" :item="item" :gameMode="true"></promoItem>
        </div>
        <div class="game-reward-promo game-reward-promo-holder" v-else-if="prizePromoHolder">
          <div class="win-promo-title">{{this.prizePromoHolder}}</div>
        </div>

        <template #customFooter>
          <div class="modal-buttons-large mt-0">
            <div @click="toMainPage" class="btn btn-secondary">Главное меню</div>
            <div class="btn btn-primary" @click="nextlevel()">Следующий уровень</div>
<!--            <button @click="startNew" tabindex="-1" class="btn btn-primary">Следующий уровень</button>-->
          </div>
        </template>

      </Modal>

      <Modal
          v-model="checkModal"
          :title="'Проверка!'"
          @close="closeModal"
      >
        <div class="buy-popup-text">
          Осторожно! Нажатие на кнопку "Проверить" может стереть все ваши достижения. Будьте внимательны и продвигайтесь к цели без ошибок.
        </div>

        <template #customFooter>
          <div class="modal-buttons-large mt-0">
            <div class="btn btn-secondary" @click="closeModal()">Отмена</div>
            <div class="btn btn-primary" @click="check">Проверить</div>
<!--            <button @click="startNew" tabindex="-1" class="btn btn-primary">Следующий уровень</button>-->
          </div>
        </template>

      </Modal>

    </div>
  </div>
</template>

<script>
import { RouterLink, RouterView } from 'vue-router'
import { useUserStore } from '@/store/user';
import { mapState, mapActions } from 'pinia';
import cell from '@/components/games/sudoku/cell.vue'
import tips from '@/components/games/tips.vue'
import Modal from '@/components/modals/Modal.vue'
import promoItem from '@/components/promo/promoItem.vue';

const sudokuState = localStorage.getItem('sudokuState');

export default {
  name: 'SudokuGame',
  components: {
    RouterLink,
    RouterView,
    cell,
    tips,
    Modal,
    promoItem
  },
  data() {
    return {
      activeCell: false,
      grid: [],
      baseState: '',
      gridArr: '',
      arrTemp: [],
      tempString: '',
      gameType: '',
      failModal: false,
      winModal: false,
      prizes: null,
      prizePromo: null,
      prizePromoHolder: null,
      checkModal: null,
      tipsLayout: '',
      tips: [],
      newTipsLayout: '',
      gameFinished: false,
      contentLoading: true,
      loadLevel: false,
      reset: false,
      tstate: [],
      gameLoad: false,
      checkGameProgress: false,
    };
  },
  async created() {
    this.gameType = this.$route.meta.gameType;
    // this.gameData = await this.sudokuGame();
    if (this.games.sudoku.content.layout.length == 0) {
      let response = await this.sudokuGameContinue();
      if (response.redirect) {
        window.location.href = (response.redirect);
      } else {
        this.gameData = response;
      }
    }
    this.updateUser();
  },
  async mounted() {
    this.getUserInfo();
  },
  computed: {
    ...mapState(useUserStore, ['userInfo', 'games', 'sudokuErrors']),
    gameInfo() {
      return this.games.sudoku
    },
    gameGrid() {
      let temp = localStorage.getItem('sudokuCurrentState');
      this.grid = [];
      this.tstate = [];
      this.baseState = this.games.sudoku.content.layout;
      this.gridArr = temp ? temp : this.baseState;

      if (this.games.sudoku.tips){
        this.games.sudoku.tips.forEach((el) => {
          if (el.id==21 && el.status==-2)
            this.tipErrors(true)
        });
      }

      this.checkReset();

      if (this.newTipsLayout.length > 0){
        this.tipsLayout = this.newTipsLayout;
      }
      else if (this.games.sudoku.state){
        this.tipsLayout = this.games.sudoku.state.tips_layout ? this.games.sudoku.state.tips_layout : '';
      }

      if (this.games.sudoku.state){
        if (this.games.sudoku.state.tips){
          Object.keys(this.games.sudoku.state.tips).forEach(key => {
            if (this.games.sudoku.state.tips[key].data.seconds_before_expiration){
              Object.keys(this.tips).forEach(i => {
                if (this.tips[i]){
                  if (this.tips[i].id == this.games.sudoku.state.tips[key].t){
                    this.tips[i].seconds_before_expiration = this.games.sudoku.state.tips[key].data.seconds_before_expiration
                  }
                }
              })
            }

          })
        }
      }



      for (var i = 0; i < this.gridArr.length; i++) {
        let el = {};
        let row  = Math.ceil((i+1) / 9);
        let col = parseInt( ((i/9 + 1) - row)*10 + 1 );
        let block = ( Math.ceil(col / 3) + (Math.ceil(row / 3) - 1) * 3);
        el.row = row;
        el.col = col;
        el.block = block;
        el.value = this.gridArr[i] != 0 ? this.gridArr[i] : '';
        el.index = i;
        el.locked = this.gridArr[i] != 0 ? true : false;
        if (temp && this.gridArr[i]!=this.games.sudoku.content.layout[i]){
          el.locked = false;
        }
        if ((this.gridArr[i] != this.tipsLayout[i]) && (this.tipsLayout[i] !=0)){
          el.tipsCell = true;
          el.value = this.tipsLayout[i];
          el.locked = true;
        }
        this.grid.push(el);

        if (this.gridArr[i]!=0 || this.tipsLayout[i] !=0)
          this.tstate.push(this.gridArr[i])

      }

      if (this.tstate.length > 79)
        this.disableTip(19,this.gameType);
      if (this.tstate.length > 75)
        this.disableTip(20,this.gameType);

      if (this.sudokuErrors){
        Object.keys(this.grid).forEach(key => {
          let errArr = [];
          if (this.grid[key].value!='' && !this.grid[key].locked) {
            Object.keys(this.grid).forEach(i => {
              if (key != i) {
                if ((this.grid[key].row == this.grid[i].row) || (this.grid[key].col == this.grid[i].col) || (this.grid[key].block == this.grid[i].block) && this.grid[i].value != ''  && this.grid[i].value != 0) {
                  let val = this.grid[i].value ? this.grid[i].value : '';
                  if (val)
                    errArr.push(val);
                }
              }
            });
          }
          this.grid[key].error = errArr.includes(this.grid[key].value);
        });
      }



      this.contentLoading = false;
      this.arrTemp = this.grid;
      return this.grid;
    },
    tipsArr(){
      this.tips = this.games.sudoku.tips
      return this.tips
    }
  },
  watch: {
    userInfo: function (val) {
      if (val.is_suspended == 1){
        this.$router.push('/');
        this.openSuspendModal();
      }
    },
  },
  methods: {
    ...mapActions(useUserStore, ['sudokuGame', 'checkGame', 'getUserInfo', 'sudokuGameContinue', 'buyTip', 'tipErrors', 'disableTip', 'enableTip', 'openSuspendModal']),
    toMainPage() {
      setTimeout(() => {
        this.$router.push({name: `home`});
      }, 100);
      this.winModal = false;
      this.failModal = false;
    },
    pickCell(el){
      this.activeCell = el;

      Object.keys(this.grid).forEach(key => {
        this.grid[key].highlighted = false;
        if ( (this.grid[key].row == this.grid[this.activeCell.index].row) || (this.grid[key].col == this.grid[this.activeCell.index].col) || (this.grid[key].block == this.grid[this.activeCell.index].block)){
          this.grid[key].highlighted = true;
        }
      })
    },
    checkReset(el){
      let counter = 0;
      if(this.games.sudoku.content && this.games.sudoku.state){
        let temp = localStorage.getItem('sudokuCurrentState') ? localStorage.getItem('sudokuCurrentState') : this.games.sudoku.state.answer;

        this.reset = this.games.sudoku.content.layout === this.games.sudoku.state.answer ? false : true;


        for (var i = 0; i < this.games.sudoku.content.layout.length; i++) {
          if ( this.games.sudoku.content.layout[i]!=temp[i] && temp[i] != this.games.sudoku.state.tips_layout[i] ){
            counter++
          }
        }
      }
      this.reset = counter > 0 ? true : false
    },
    cellFill(ind){
      if (this.activeCell){
        this.grid[this.activeCell.index].value = ind;
        let errArr = [], totalArr = [];
        if (this.sudokuErrors) {
          Object.keys(this.grid).forEach(key => {
            if (key != this.grid[this.activeCell.index].index) {
              if ((this.grid[key].row == this.grid[this.activeCell.index].row) || (this.grid[key].col == this.grid[this.activeCell.index].col) || (this.grid[key].block == this.grid[this.activeCell.index].block)) {
                let val = this.grid[key].value ? this.grid[key].value : 0;
                errArr.push(parseInt(val));
              }
            }
          });
          this.grid[this.activeCell.index].error = errArr.includes(this.grid[this.activeCell.index].value);
        }

        Object.keys(this.grid).forEach(key => {
          if (this.grid[key].value){
            totalArr.push(this.grid[key].value)
          }
        })

        this.saveState();

        if (totalArr.length > 79)
          this.disableTip(19,this.gameType);
        else
          this.enableTip(19,this.gameType)
        if (totalArr.length > 75)
          this.disableTip(20,this.gameType);
        else
          this.enableTip(20,this.gameType)

        this.checkReset();
        if (totalArr.length == 81){
          this.checkModal = true;
        }

      }
    },
    inputCell({ num, el }){
      this.pickCell(el)
      this.cellFill(parseInt(num))
    },
    clearCell(el){
      this.grid[el.index].value = '';
      this.grid[el.index].error = false;
      this.checkReset();
    },
    unSelectCell(){
      this.activeCell = false;
      Object.keys(this.grid).forEach(key => {
        this.grid[key].highlighted = false;
      })
    },
    gameReset(){
      this.check(false)
      this.tempString = this.baseState;
      this.grid = [];
      localStorage.removeItem('sudokuCurrentState');
    },
    nextlevel(){
        this.getUserInfo();
        this.loadLevel = true;
        this.winModal = false;
        this.failModal = false;
        this.checkModal = false;
        if (this.userInfo.games[this.gameType].statsByLevels.month_levels_balance <= 0) {
          this.$router.push("/sudoku");
        } else {
          this.startGame();
        }
    },
    async startGame(){
      if (this.gameLoad) {
        return false;
      }

      this.gameLoad = true;
      let response = await this.sudokuGame( {
        'level': this.userInfo.sudokuLevel
      });
      this.gameReset();
      if (response){
        if (response.redirect)
          window.location.href = (response.redirect);
        else
            this.$router.go(0);
      } else {
        this.$router.push("/sudoku");
      }
      this.contentLoading = false;
    },
    saveState(){
      this.tempString = '';
      Object.keys(this.grid).forEach(key => {
        if(!this.grid[key].tipsCell)
          this.tempString+=this.grid[key].value!=''  ? this.grid[key].value : 0;
        else
          this.tempString+=0
      });
      localStorage.setItem('sudokuCurrentState', this.tempString);
    },
    confirmClose() {
      this.nextlevel();
    },
    closeModal() {
      this.checkModal = false;
    },
    countDownEnd(tip){
      tip.expires_at = null
    },
    async startNew() {
      await this.sudokuGame({
        'level': this.userInfo.sudokuLevel
      });
      this.winModal = false;
      this.failModal = false;
    },
    async updateUser() {
      await this.getUserInfo();
    },
    async check(res){
      if (this.checkGameProgress) {
        return
      }
      this.checkGameProgress = true;
      let isFinal = res ? true : res;
      this.tempString = '';
      Object.keys(this.grid).forEach(key => {
        if(this.grid[key].value == this.tipsLayout[key] )
          this.tempString+=this.tipsLayout[key];
        else
          this.tempString+=this.grid[key].value!='' ? this.grid[key].value : 0;
      });
      this.contentLoading = true;
      let response = await this.checkGame({
        gameType: this.gameType,
        params: {
          answer: this.tempString,
          isFinal: isFinal,
          // forceWin: 1
        }
      });
      if (!this.gameFinished){

        if (response && response.result && isFinal){
          this.userInfo.games.sudoku.qtyCompleted = response.qty;
          this.userInfo.games.sudoku.qtySuccess = response.qty_success;
          this.userInfo.diamonds+= response.diamonds;
          this.userInfo.points+= response.points;
          this.userInfo.puzzle+= response.puzzleQty;
        }

        if (response.result == 'fail' && isFinal){
          this.checkModal = false;
          this.failModal = true;
        }
        if (response.result == 'win'){
          this.winModal = true;
          this.checkModal = false;
          if (response.points > 0 || response.puzzleQty > 0 || response.diamonds > 0){
            this.prizes = {};
            this.prizes.points = response.points;
            this.prizes.puzzle = response.puzzleQty;
            this.prizes.diamonds = response.diamonds;
          }

          if (response.prizeContent && response.prizeContent.length > 0){
            this.prizePromo = response.prizeContent
          }
          // else if (response.qtyGamesForNextPromocode && response.qtyGamesForNextPromocode > 0) {
          //   this.prizePromoHolder = `Побед до следующего промокода: ${response.qtyGamesForNextPromocode}`
          // }
          // else{
          //   this.prizePromoHolder = 'На сегодня все промокоды разыграны';
          // }

        }
        if (isFinal){
          this.gameFinished = true;
          localStorage.removeItem('sudokuCurrentState');
        }

        if(response)
          this.contentLoading = false;
      }
      this.checkGameProgress = false;

    },
    async buy(tip){
      this.contentLoading = true;
      this.saveState()
      this.check(false);
      let temp = localStorage.getItem('sudokuCurrentState') ? localStorage.getItem('sudokuCurrentState') : this.tempString;
      let layout = temp.length > 0 ? temp : this.baseState
      const response = await this.buyTip({
        'tip': tip.id,
        'answer': layout,
      }, this.gameType);
      this.newTipsLayout = response.layout;
      this.contentLoading = false;
    }
  },
  beforeRouteLeave(to, from) {
    this.saveState()
    this.getUserInfo()
    if (!this.gameFinished)
      this.check(false);
  },
};
</script>
